<template>
    <header class="flex justify-center fixed md:relative top-0 left-0 right-0">
      <h1 class="text-2xl">Pendudujour.fr</h1>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderCustom'
  }
  </script>
  
  <style>
  /* Styles supplémentaires si nécessaire */
  </style>
