<template>
    <div id="back-grayed" v-if="show" class="fixed inset-0 bg-opacity-90 overflow-y-auto h-full w-full" @click.self="close">
      <div id="modal" class="absolute inset-x-0 bottom-10 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
          <div class="mt-2 px-7 py-3">
            <p class="text-sm text-gray-500">
              <span class = "congratulations" v-if="isSuccess">Félicitations ! Vous avez trouvé le mot !</span>
              <span class = "blame" v-if="!isSuccess"> Dommage ! Le mot était : {{ word }}</span>
            </p>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
            A demain pour un autre mot !
          </h3>
          </div>
         <!-- <div class="items-center px-4 py-3">
            <button id="ok-btn" @click="close" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300">
              Ok
            </button>
          </div>-->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true
      },
      isSuccess: {
        required: true
      },
      word: {
        required: true
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  };
  </script>
  

  <style>
#modal{
  background-color: #ffffffe3;
}
#back-grayed{
  background-color: #7575758a;
}


</style>