<template>
    <span class="text-2xl"  :class="letterClass">{{ letter }}</span>
  </template>
  
  <script>
  export default {
    name: 'GuessedLetter',
    props: {
      letter: String,
      state: {
        type: String,
        validator: value => ['valid', 'invalid'].includes(value)
      }
    },
    computed: {
      letterClass() {
        return {
          'valid-class': this.state === 'valid',
          'invalid-class': this.state === 'invalid'
        };
      }
    }
  };
  </script>
  
  <style>
  .valid-class {
    /* Styles pour les lettres valides */
    color: green;
  }
  
  .invalid-class {
    /* Styles pour les lettres invalides */
    color: red;
  }
  </style>
  