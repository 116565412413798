<template>


  <div id="app" class="flex flex-col h-screen justify-between">
    <HeaderCustom> </HeaderCustom>
<!--
    <div v-if="gameState === GameState.Won" class="congratulations p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
        
      </div>
      <div v-if="gameState === GameState.Lost" class="blame">
        Dommage ! Le mot était {{word}}
      </div>-->
    
      <div class="flex  md:flex-row flex-col">

  

        <div id="main-left" class="md:flex-1 justify-center items-end p-4">
            <div class="flex justify-center  p-4">
            <HangmanDisplay :wrongGuesses="wrongGuesses"/>
          </div>
        </div>

        <div id="main-right" class="md:flex-1 space-y-5 p-4">
          <div id="guessedLettersDisplay" class="flex justify-center font-semibold	space-x-4">
        <GuessedLetter 
          v-for="(item, index) in guessedLetters" 
          :key="index"
          :letter="item" 
          :state="word.includes(item) ? 'valid' : 'invalid'" 
        />
        </div>
          <div id="attemptsRemaining" class="flex justify-center ">
            Tentatives restantes : {{ attemptsRemaining }}
          </div>
      </div>
    </div>

        
    <div class="flex justify-center space-x-4 md:space-x-4">
          <span v-for="(letter_w, index) in splittedWord" class="text-2xl" :key="index">
            {{ guessedLetters.includes(letter_w) ? letter_w : '_' }}
          </span>
      </div>
      

    <div id="letterButtons" class="flex flex-wrap justify-center mr-2">
      <!-- Affiche les boutons des lettres -->
    <LetterButton
      v-for="letter in alphabet"
      :key="letter"
      :letter="letter"
      :isChosen="guessedLetters.includes(letter)"
      @click="guessLetter(letter)"
    />
  </div>

  <ModalGameOver :show="gameIsOver" @close="gameIsOver = false" :isSuccess="gameState == GameState.Won" :word="word"></ModalGameOver>

    <footer class="footer bg-gray-200 text-right p-4">
    <p>By <a target="_blank" rel="noopener noreferrer" href="https://salembenmabrouk.com" class="text-blue-500 hover:text-blue-700">Salem</a></p>
    </footer>


  </div>
</template>

<script>
import HangmanDisplay from './components/HangmanDisplay.vue';
import LetterButton from './components/LetterButton.vue';
import HeaderCustom from './components/HeaderCustom.vue';
import GuessedLetter from './components/GuessedLetter.vue';
import ModalGameOver from './components/ModalGameOver.vue'
import axios from 'axios';


const GameState = {
  Pending: 'Pending',
  Started: 'Started',
  Won: 'Won',
  Lost: 'Lost'
};

export default {
  name: 'App',
  components: {
    HangmanDisplay,
    LetterButton,
    HeaderCustom,
    GuessedLetter,
    ModalGameOver
  },
  data() {
    return {
      word: '',
      guessedLetters: [],
      wrongGuesses: 0,
      maxWrongGuesses: 7,
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      gameState: GameState.Started,
      GameState
    };
  },
computed: {
  attemptsRemaining() {
    return this.maxWrongGuesses - this.wrongGuesses;
  },
  splittedWord() {
    return this.word.split('')
  },
  gameIsOver(){
    return this.gameState == GameState.Lost || this.gameState == GameState.Won
  }
},
mounted() {
  this.fetchWordOfTheDay();
},
methods: {
  guessLetter(selectedLetter) {
    if (this.gameState == GameState.Pending){
      this.gameState = GameState.Started
    }
    if (this.gameIsOver){
      return
    }
    // Ajouter la lettre choisie à l'ensemble des lettres devinées
    this.guessedLetters.push(selectedLetter);

    // Vérifier si la lettre choisie est dans le mot
    if (!this.splittedWord.includes(selectedLetter)) {
      // Si la lettre n'est pas dans le mot, augmenter le nombre de tentatives incorrectes
      this.wrongGuesses++;
    }

    // Vérifier si le jeu est terminé
    this.checkGameOver();
  },
  checkGameOver() {
    // Vérifier si le joueur a gagné
    this.hasWon = this.splittedWord.every(letter => this.guessedLetters.includes(letter));

    if (this.hasWon) {
      this.gameState = GameState.Won
      // Ici, vous pouvez ajouter du code pour gérer la victoire (par exemple, réinitialiser le jeu ou afficher un message de félicitations)
    }

    // Vérifier si le joueur a perdu
    if (this.wrongGuesses >= 7) { 
      // Ici, vous pouvez ajouter du code pour gérer la défaite (par exemple, réinitialiser le jeu ou afficher un message)
      this.gameState = GameState.Lost
    }
  },
  fetchWordOfTheDay() {
      axios.get('https://yuo4jndlvik5l2zt6zjljk5omy0gphzw.lambda-url.eu-west-3.on.aws/')
        .then(response => {
          this.word = response.data; // Supposons que la réponse contient directement le mot
        })
        .catch(error => {
          console.error("Erreur lors de la récupération du mot du jour: ", error);
          // Gérer l'erreur comme vous le souhaitez
        });
    }
}

};
</script>

<style>


.congratulations {
  color: green;
  font-size: 1.5rem;
  margin-top: 20px;
  /* Autres styles pour embellir le message */
}
.blame {
  color: red;
  font-size: 1.5rem;
  margin-top: 20px;
  /* Autres styles pour embellir le message */
}
</style>
