<template>
  <button 
    @click="handleClick" 
    :disabled="isChosen"
    :class="{ 'greyed-out': isChosen }"
    class="bg-transparent hover:bg-blue-100 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded ml-2 mt-2"
    >
    {{ letter }}
    
  </button>
</template>

<script>
export default {
  props: ['letter', 'isChosen'],
  methods: {
    handleClick() {
      //this.$emit('click');
    }
  }
};
</script>


<style>
.greyed-out {
  color: #ccc;
  background-color: rgb(176, 174, 174);
  border: 1px solid #ccc;
  cursor: not-allowed;
}
</style>
